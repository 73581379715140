// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// assets
import {
    IconAlertCircle,
    IconTruckDelivery,
    IconFileShredder,
    IconBuildingFactory,
    IconFileImport,
    IconBoxMultiple,
    IconLayoutGrid,
    IconListNumbers,
    IconArrowBackUp,
    IconBox
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

function displayProducStrains(strains) {
    if (!strains) {
        return 'N/A';
    }
    // join the array and avoid doubles
    const uniqueStrains = [...new Set(strains)];
    return uniqueStrains.join(', ');
}

function returnTraysWeightOrQuantity(data) {
    if (data?.traysIds > 0) return data?.traysIds?.length;
    return data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
}

function createItemData(type, data) {
    let icon;
    let primaryText;
    let colorKey = 'default';
    let weight = 0;
    let date = '';
    let strains = '';
    let batchId = '';
    if (type.startsWith('all')) {
        type = data?.type;
    }

    switch (type) {
        case 'destructions':
            icon = <IconFileShredder stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'error';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'expeditions':
            icon = <IconTruckDelivery stroke={1.5} size="20px" />;
            primaryText = data.action.startsWith('No action') ? "Expédition de l'usine" : data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'warning';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'importations':
            icon = <IconFileImport stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'primary';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'product-conversions':
            icon = <IconBuildingFactory stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'info';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'storage-conversions':
            icon = <IconBoxMultiple stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'success';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'trays':
            icon = <IconBox stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = returnTraysWeightOrQuantity(data);
            colorKey = 'secondary';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'batches':
            icon = <IconListNumbers stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'warning';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        case 'sublots':
            icon = <IconLayoutGrid stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'info';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
        default:
            icon = <IconAlertCircle stroke={1.5} size="20px" />;
            primaryText = data.action;
            date = data.action_date;
            weight = data?.is_weight ? `${parseFloat(data?.weight).toFixed(3)} kg` : parseFloat(data?.weight);
            colorKey = 'default';
            strains = displayProducStrains(data?.product_strains);
            batchId = data?.batch_id;
            break;
    }
    return { icon, colorKey, weight, primaryText, date, strains, batchId };
}

const ListItemComponent = ({ isSubmitting, type, data, handleClick }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { icon, colorKey, weight, primaryText, date, strains, batchId } = createItemData(type, data);

    const color = theme.palette[colorKey];
    return (
        <>
            <ListItem alignItems="center">
                <ListItemAvatar>
                    <Avatar
                        sx={{
                            color: color?.dark, // Use dark shade of the color
                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : color?.light, // Use light shade for light mode
                            border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                            borderColor: color?.main // Use main color
                        }}
                    >
                        <strong>{icon}</strong>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={<strong>{batchId}</strong>}
                    sx={{
                        '& .MuiTypography-root': {
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
                        }
                    }}
                />
            </ListItem>
            <Grid container direction="row" className="list-container" mb={2}>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="subtitle2">
                        <strong>{date}</strong>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" className="list-container" mb={2}>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="subtitle2">
                        <strong>{primaryText}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">{weight}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" className="list-container" mb={2}>
                <Grid
                    item
                    xs={6}
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="subtitle2">
                        <strong>{strains}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {/* icon to go to details */}
                    <IconButton
                        onClick={() => {
                            handleClick(type, data);
                        }}
                        disabled={isSubmitting}
                        sx={{
                            color: theme.palette.primary.main,
                            '&:hover': {
                                color: theme.palette.primary.dark
                            }
                        }}
                    >
                        <IconArrowBackUp stroke={2} size="20px" />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const UndoList = ({ data, setIsSubmitting, isSubmitting }) => {
    const theme = useTheme();
    const { executeUndo } = useAuth();
    const dispatch = useDispatch();

    const handleClick = async (type, data) => {
        const payload = {
            type: data.type || type,
            id: data.id,
            action_date: data.action_date
        };
        try {
            setIsSubmitting(true);
            const response = await executeUndo(payload);
            if (response && response?.success) {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: response?.data?.message || 'Undo successful',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true,
                        duration: 1000
                    })
                );
                setTimeout(() => {
                    window.location.reload();
                }, 400);
            }
        } catch (error) {
            console.error(error);
            dispatch(
                openSnackbar({
                    open: true,
                    message: error?.message || 'An error occurred',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true,
                    duration: 6000
                })
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const chipSX = {
        height: 24,
        padding: '0 6px',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {data?.data?.map((item, index) => (
                <ListItemWrapper key={index}>
                    <ListItemComponent isSubmitting={isSubmitting} type={data.type} data={item} handleClick={handleClick} />
                    <Divider />
                </ListItemWrapper>
            ))}
        </List>
    );
};

export default UndoList;
