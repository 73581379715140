// third-party
import { createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';

// ----------------------------------------------------------------------

const initialState = {
    product: '',
    batch: '',
    batches: [],
    installationbatches: [],
    stagebatches: [],
    installationstagebatches: [],
    traybatches: [],
    traybatch: '',
    retentions: [],
    sublots: [],
    sublot: ''
};

const slice = createSlice({
    name: 'batch',
    initialState,
    reducers: {
        getSingleProductSuccess(state, action) {
            state.product = action.payload;
        },
        getSingleBatchSuccess(state, action) {
            state.batch = action.payload;
        },
        getAllBatchesSuccess(state, action) {
            state.batches = action.payload;
        },
        getInstallationBatchesSuccess(state, action) {
            state.installationbatches = action.payload;
        },
        getStageBatchesSuccess(state, action) {
            state.stagebatches = action.payload;
        },
        getInstallationStageBatchesSuccess(state, action) {
            state.installationstagebatches = action.payload;
        },
        getTrayBatchesSuccess(state, action) {
            state.traybatches = action.payload;
        },
        getSingleTrayBatchSuccess(state, action) {
            state.traybatch = action.payload;
        },
        getSublotsSuccess(state, action) {
            state.sublots = action.payload;
        },
        getSingleSublotSuccess(state, action) {
            state.sublot = action.payload;
        },
        resetBatchState(state) {
            state.batch = [];
            state.sublot = [];
        },
        getSingleBatchFailure(state, action) {
            state.batch = action.payload;
        },
        getSingleSublotFailure(state, action) {
            state.sublot = action.payload;
        },
        getRetentionsSuccess(state, action) {
            state.retentions = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export const batchActions = slice.actions;

// ----------------------------------------------------------------------

export function resetSingleBatch() {
    return () => {
        dispatch(slice.actions.resetBatchState());
    };
}

export function getSinlgeProduct(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/products/index/${id}`);
            dispatch(slice.actions.getSingleProductSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleBatch(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/single-batch/${id}`);
            dispatch(slice.actions.getSingleBatchSuccess(response.data.data));
        } catch (error) {
            console.error('ERROR FETCHING SINGLE BATCH', error);

            const message = error?.message || 'Erreur lors de la récupération du batch';
            dispatch(
                openSnackbar({
                    open: true,
                    message,
                    variant: 'alert',
                    alert: { color: 'error' },
                    duration: 5000,
                    close: true
                })
            );
            if (error?.status === 404) {
                window.dispatchEvent(new CustomEvent('appNavigate', { detail: '/404' }));
            }
            dispatch(slice.actions.getSingleBatchFailure(error));
        }
    };
}
export function getAllBatches() {
    return async () => {
        try {
            const response = await axios.get(`/api/batches/show`);
            dispatch(slice.actions.getAllBatchesSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getInstallationBatches() {
    return async () => {
        try {
            const response = await axios.get(`/api/installation-batches/show`);
            dispatch(slice.actions.getInstallationBatchesSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getInstallationStageBatches(stage) {
    return async () => {
        try {
            const response = await axios.get(`/api/installation-stage-batches/${stage}`);
            dispatch(slice.actions.getInstallationStageBatchesSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getTrayBatches() {
    return async () => {
        try {
            const response = await axios.get(`/api/trays-batches`);
            dispatch(slice.actions.getTrayBatchesSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleTrayBatch(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/single-tray-batch/${id}`);
            dispatch(slice.actions.getSingleTrayBatchSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING SINGLE TRAY BATCH', error);
        }
    };
}
export function getSublots() {
    return async () => {
        try {
            const response = await axios.get(`/api/sublots/show`);
            dispatch(slice.actions.getSublotsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING', error);
        }
    };
}
export function getSingleSublot(id) {
    return async () => {
        try {
            const response = await axios.get(`/api/sublots/show/${id}`);
            dispatch(slice.actions.getSingleSublotSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING RETENTIONS', error);
        }
    };
}

export function getRetentions() {
    return async () => {
        try {
            const response = await axios.get('/api/retention/trays/');
            dispatch(slice.actions.getRetentionsSuccess(response.data.data));
        } catch (error) {
            console.log('ERROR FETCHING SINGLE SUBLOT', error);
        }
    };
}
