/* eslint-disable react/prop-types */
// routing
import Routes from 'routes';
import Pusher from 'pusher-js';
import { useEffect } from 'react';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import { QueryClient, QueryClientProvider } from 'react-query';

import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //
if (process.env.NODE_ENV === 'development') {
    const originalWarn = console.warn;
    console.warn = function (message, ...args) {
        if (message.startsWith('MUI: You have provided an out-of-range value')) {
            return;
        }
        originalWarn.call(console, message, ...args);
    };
}

window.Pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
});

const queryClient = new QueryClient();

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleNavigation = (event) => {
            navigate(event.detail);
        };

        window.addEventListener('appNavigate', handleNavigation);

        return () => {
            window.removeEventListener('appNavigate', handleNavigation);
        };
    }, [navigate]);

    return (
        <ThemeCustomization>
            {/* <RTLLayout> */}
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <QueryClientProvider client={queryClient}>
                            <>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Routes />
                                    <Snackbar />
                                </LocalizationProvider>
                            </>
                        </QueryClientProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
            {/* </RTLLayout> */}
        </ThemeCustomization>
    );
};

export default App;
